/*
// .site-header
*/
@import '../variables';
@import '../functions';
@import '../mixins/direction';


.site-header {
    position: relative;
    z-index: 100;
    background: $header-bg;
    color: $header-font-color;
}
.site-header__middle {
    display: flex;
    align-items: center;
    height: 55px;
}
.site-header__logo {
    width: 255px;
    display: flex;
    align-items: left;
    justify-content: left;
    color: $header-logo-color;

    @include direction {
        #{$margin-inline-end}: 90px;
    }

    a {
        color: inherit;
    }

    svg {
        fill: currentColor;
    }
}
.site-header__search {
    flex-grow: 1;
}
.site-header__phone {
    //line-height: 1;

    @include direction {
        text-align: $inline-end;
        #{$margin-inline-start}: 10px;
    }
}

.site-header__nav-panel {
    height: $nav-panel-height;
}


@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    .site-header__logo {
        width: 300px;
        justify-content: flex-start;

        @include direction {
            #{$margin-inline-end}: 24px;
        }
    }
}