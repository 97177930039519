// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------


.overlay {
    display: none;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 100000;

  }

  .overlay.show {
    display: block;
  }

/* Set your aspect ratio */
.video-container {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: 56.25%; /* creates a 16:9 aspect ratio */
  }

  .video-container iframe,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }

  /* And set the max-width of the parent element */
  .video-wrap-right {
    width: 100%;
    max-width: 600px;
    float: right;
    margin: 0px 10px 20px 10px;
  }


  .video-wrap-50-right {
    width: 100%;
    max-width: 640px;
    float: right;
    margin: 0px 0px 10px 10px;
  }


  .video-wrap-right-desktop {
    width: 100%;
    max-width: 400px;
    float: right;
    margin: 0px 10px 20px 10px;
  }


  .video-wrap-mobile {
    width: 100%;
    max-width: 600px;
    margin: 0px 0px 20px 0px;
  }


  .video-wrap-faq {
    width: 100%;
    max-width: 500px;

  }

//NOG OVERLEGGEN MET PIM

.linkbutton {

    list-style: none;
    display: inline-block;
    border: 1px solid #cac7c3;
    padding: 4px 12px 4px 12px;
    border-radius: 15px;
    margin: 3px;
}


.spanh2 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 1.2;
  }

.falldownmenuheader {
    margin-bottom: .5rem;
    line-height: 1.2;
    margin-top: 0;
    font-size: 16px;
    font-weight: 700;
}

.spanh3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 1.2;
  }




:root {
    scroll-behavior: auto;
}

.th-productinfo {
    font-weight: normal;
    width: 50%;
}

.border-bottom {
    border-bottom: 2px solid $secondary;
}

.black {
    color: $body-font-color;
}

.inline-list__item {
    display: inline-block;
    vertical-align: middle;
}
.inline-list__item--gap-4:not(:last-of-type) {
    margin-right: 2rem;
}
.inline-list {
    list-style: none;
    max-height: 20px;
    overflow: hidden;
}

hr {
    border-top: 0px;
    border-bottom: 2px solid #c1c1c1 !important;

}

.readmore {
    padding-top: 1px;
    display: block;
    color: #0090e3;
}

.readmore:hover {
    color: #0090e3;
    cursor: pointer;
    text-decoration: underline;
}

.fadehide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0s;
    -webkit-transition: opacity 0s;
    -moz-transition: opacity 0s;
    -o-transition: opacity 0s;
}

.fadeshow {
    visibility: visible;
    opacity: 0.4;
    transition: visibility 0s, opacity 0.4s ease;
    -webkit-transition: opacity 0.4s ease;
    -moz-transition: opacity 0.4s ease;
    -o-transition: opacity 0.4s ease;
}

.bluelink:hover {
    text-decoration: underline;
}

.blacklink,
.blacklink:hover,
.blacklink:active,
.blacklink:visited {
    color: #000;
}

.headerblueresponsive {
    color: #365484;
    font-weight: bold;
    font-size: 24px;
    display: block;
    //padding-bottom: 50px;
}

.products-view__options {
}

// bs-datepicker
tbody tr.ng-star-inserted td.ng-star-inserted span.is-other-month {
    display: none;
}

tr.ng-star-inserted td.ng-star-inserted span.disabled {
    border: 0px;
}

tr.ng-star-inserted td.ng-star-inserted span {
    border: 1px #9aaec1 solid;
    border-radius: 0;
    margin: 2px;
    width: 30px;
    height: 30px;
}

bs-datepicker-inline
    bs-datepicker-inline-container.bottom.ng-tns-c97-0.ng-star-inserted
    div.bs-datepicker.ng-tns-c97-0.theme-green.ng-star-inserted
    div.bs-datepicker-container.ng-tns-c97-0.ng-trigger.ng-trigger-datepickerAnimation
    div.bs-calendar-container.ng-tns-c97-0
    div.bs-media-container.ng-tns-c97-0.ng-star-inserted
    bs-days-calendar-view.ng-tns-c97-0.bs-datepicker-multiple.ng-star-inserted
    bs-calendar-layout
    div.bs-datepicker-head {
    background-color: #009600;
}

div.bs-datepicker-head bs-datepicker-navigation-view button.previous {
    background-color: #5cb85c;
}

//bs-datepicker
tbody tr.ng-star-inserted td.ng-star-inserted {
    color: #000;
}

.btn-light {
    fill: #ff7100;
}

//.dropcart__product-image

//  div.bs-datepicker-body table.days.weeks tbody tr.ng-star-inserted td.ng-star-inserted span {

//     color: red;
//  }

.fab1 {
    display: inline-block;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: #5e5351;
    width: 15px;
    height: 15px;
    background-size: contain;
    margin-right: 5px;
    position: relative;
    top: -1px;
}

.fab2 {
    display: inline-block;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: #5e5351;
    width: 15px;
    height: 15px;
    background-size: contain;
    margin-right: 5px;
    position: relative;
    top: 2px;
}

.fab3 {
    display: inline-block;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: #fff;
    width: 15px;
    height: 15px;
    background-size: contain;
    margin-right: 5px;
    position: relative;
    top: 2px;
}

.fab4 {
    display: inline-block;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: #5e5351;
    width: 15px;
    height: 15px;
    background-size: contain;
    margin-right: 5px;
    position: relative;
}

.fa-facebook-square {
    -webkit-mask: url('/assets/images/icons/facebook.svg') no-repeat 100% 100%;
    mask: url('/assets/images/icons/facebook.svg') no-repeat 100% 100%;
}

.fa-instagram {
    -webkit-mask: url('/assets/images/icons/instagram.svg') no-repeat 100% 100%;
    mask: url('/assets/images/icons/instagram.svg') no-repeat 100% 100%;
}

.fa-youtube {
    -webkit-mask: url('/assets/images/icons/youtube.svg') no-repeat 100% 100%;
    mask: url('/assets/images/icons/youtube.svg') no-repeat 100% 100%;
}

.fa-pinterest {
    -webkit-mask: url('/assets/images/icons/pinterest.svg') no-repeat 100% 100%;
    mask: url('/assets/images/icons/pinterest.svg') no-repeat 100% 100%;
}

.fa-share {
    -webkit-mask: url('/assets/images/icons/share.svg') no-repeat 100% 100%;
    mask: url('/assets/images/icons/share.svg') no-repeat 100% 100%;
}

.owl-prev {
    display: block;
    background-color: #ff7100;
    margin-right: 5px;
    margin-top: 5px;
    padding: 8px 12px 8px 12px;
    color: #fff;
    border-radius: 7px;
    font-size: 16px;
    float: left;

    background-image: url('/assets/images/icons/arrow-white-left.svg');
    background-size: 9px;
    background-repeat: no-repeat;
    background-position: 7px 13px;
}

.owl-next {
    display: block;
    background-color: #ff7100;
    margin-left: 5px;
    margin-top: 5px;
    padding: 8px 12px 8px 12px;
    color: #fff;
    border-radius: 7px;
    font-size: 16px;
    float: right;
    background-image: url('/assets/images/icons/arrow-white-right.svg');
    background-size: 9px;
    background-repeat: no-repeat;
    background-position: 85px 13px;
}

.cc-window {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 999999;
}

.cc-revoke {
    display: none;
}

.cookie-container {
    font-family: Arial, 'sans-serif';
    max-width: 600px;
    border-top: 1px solid #fff;
    font-size: 14px;
    line-height: 1.5;
    background-color: #5e5351;
    padding: 10px 10px 10px 10px;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
}

.cookie-close {
    width: 75px;
    float: left;
    height: 50px;
    display: block;
}

.responsiveimg {
    aspect-ratio: attr(width) / attr(height);
    height: auto;
    width: 100%;
}


@media (min-width: 576px) {
.modal-dialog {

    margin: 7.75rem auto;
}
}

@media (min-width: breakpoint(lg-end)) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1280px;
    }
}

@media (max-width: breakpoint(md-end)) {
    .products-view__options {
        position: -webkit-sticky;
        position: sticky;
        top: 57px;
        z-index: 9;
        background-color: #fff;
        border-bottom: 1px #ededed solid;
    }

    #homepageadvbig {
        margin-bottom: 14px;
    }
}

@media (max-width: breakpoint(xs-end)) {
    .headerblueresponsive {
        font-size: 18px;
    }

    .hoverboxbrown span {
        font-size: 18px;
    }
}

@media (min-width: 990px) and (max-width: 1200px) {
    .bs-datepicker .bs-media-container {
        display: block !important;
    }
}

@media (max-width: breakpoint(xs-end)) {
    .price_xs_24 {
        font-size: 24px !important;
    }
}
