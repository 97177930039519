/*
// .ng5-slider
*/
@import '../variables';


$local-bar-height: 6px;
$local-handle-size: 16px;
$local-handle-inner-size: 6px;

// .ng5-slider-custom {
//     height: max($local-handle-size, $local-bar-height);
//     padding: (($local-handle-size - $local-bar-height) / 2) 0;

//     .ng5-slider {
//         display: block;
//         height: $local-bar-height;
//         margin: 0;

//         .ng5-slider-bubble {
//             display: none;
//         }

//         .ng5-slider-bar {
//             height: $local-bar-height;
//             border-radius: ($local-bar-height) / 2;
//         }

//         .ng5-slider-pointer {
//             width: $local-handle-size;
//             height: $local-handle-size;
//             border-radius: $local-handle-size / 2;
//             top: -(($local-handle-size - $local-bar-height) / 2);

//             &::after {
//                 width: $local-handle-inner-size;
//                 height: $local-handle-inner-size;
//                 border-radius: $local-handle-inner-size / 2;
//                 top: ($local-handle-size / 2 - $local-handle-inner-size / 2);
//                 left: ($local-handle-size / 2 - $local-handle-inner-size / 2);
//             }
//         }

//         // styles
//         .ng5-slider-bar {
//             background: $nouislider-bg-color;
//         }
//         .ng5-slider-selection {
//             background: $primary;
//         }
//         .ng5-slider-pointer,
//         .ng5-slider-pointer:hover {
//             cursor: ew-resize;
//             background: $nouislider-border-color;
//             box-shadow: 0 0 0 0 rgba($nouislider-border-color, 0);
//             transition: box-shadow .2s;

//             &::after {
//                 background: #fff;
//                 transition: transform .2s;
//             }
//         }
//         .ng5-slider-pointer:focus {
//             outline: none;
//             box-shadow: 0 0 0 3px rgba($nouislider-border-color, .3);

//             &::after {
//                 transform: scale(0);
//             }
//         }
//     }
// }
