/*
// .footer-links
*/
@import "../variables";
@import "../functions";

.footer-links {
}
.footer-links__title {
    font-size: 1.25rem;
    /*   margin-bottom: 22px; */
}
.footer-links__list {
    /* font-size: 15px; */
    line-height: 28px;
    list-style: none;
    padding: 0;
    margin: 0;

    a {
        color: inherit;
        transition: 0.15s;
    }
    a:hover {
        color: $link-hover-color;
    }
}

@media (max-width: breakpoint(sm-end)) {
    .footer-links__title {
        margin-bottom: 12px;
    }
    .footer-links {
        margin-top: 22px;
        text-align: center;
    }

    .center_form .search__form {
        margin: auto;
    }

    .footer-links__item {
        border-bottom: 1px solid #b59f97;
        text-align: left;
        height: 40px;
        padding-left: 15px;
        padding-top: 10px;
        ////background-image: url("https://m.voordeellaminaatvloeren.nl/img/pijlen/rechts-blauw.svg");
        //background-repeat: no-repeat;
        //background-position: center;
        //background-position: 97% 12px;
        //background-size: 13px;
    }
}
