/*
// .breadcrumb
*/
@import '../variables';
@import '../mixins/direction';


$local-padding: 12px;


.breadcrumb {
    background: transparent;
    padding: 0;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0;
}
.breadcrumb-item {
    position: relative;

    @include direction {
        #{$padding-inline-start}: 0;
        #{$padding-inline-end}: 2px + $local-padding * 2;
    }

    a {
        color: $breadcrumb-link-default-color;
        transition: color .15s;
    }
    a:hover {
        text-decoration: underline;
    }

    & + & {
        @include direction {
            #{$padding-inline-start}: 0;
            #{$padding-inline-end}: 6px + $local-padding * 2;
        }

        &::before {
            display: none;
        }
    }

    &.active {
        color: $breadcrumb-link-active-color;
    }
}
.breadcrumb-arrow {
    fill: $breadcrumb-arrow-color;
    position: absolute;
    top: 5px;

    @include direction {
        #{$inset-inline-end}: $local-padding - 1px;
        transform: scaleX(1 * $transform-direction);
    }
}

@media (max-width: 479px) {

    .breadcrumb-arrow {
        right: 3px !important;
    }

    .breadcrumb-item {


        @include direction {
            #{$padding-inline-start}: 0;
            #{$padding-inline-end}: 12px !important;

        }


    }
}
