/*
// .site-footer
*/
@import '../variables';
@import '../functions';


.site-footer {
    margin-top: 16px;
    background: $footer-bg;
    border-top: $footer-border-top;
}
.site-footer__widgets {
    padding: 28px 0 34px;
}

.site-footer__widget {
    & + & {
        margin-top: 24px;
    }
}

.site-footer__bottom {

    

    font-size: 14px;
    background-color: #5e5351; 
    color: #fff; 
    text-align: center; 
    
    padding-top: 10px;
    padding-bottom: 8px;
    
    a {  color: #fff;  }
}


@media (max-width: breakpoint(sm-end)) {
    .site-footer__widgets {
        padding: 0px 0px 28px 0px;

        h5 { text-align: left; padding-left: 15px; }
    }

    .site-footer__bottom {
        padding: 20px 0 24px;
        flex-wrap: wrap;
        height: auto;
    }

    .site-footer__payments {
        order: -1;
    }

    .site-footer__copyright + .site-footer__payments {
        margin-bottom: 24px;
    }

    .site-footer__copyright,
    .site-footer__payments {
        width: 100%;
        text-align: center;
    }
}
