/*
// .product-gallery
*/
@import '../variables';

.product-gallery {
}
.product-gallery__featured {
    box-shadow: $product-gallery-item-default-shadow;
    padding: 2px;
    border-radius: $product-gallery-item-border-radius;

    a {
        display: block;
        /*  padding: 20px; */
    }
}
.product-gallery__carousel {
    margin-top: 16px;
}
.product-gallery__carousel-item {
    cursor: pointer;
    display: block;
    box-shadow: $product-gallery-item-default-shadow;
    padding: 12px;
    border-radius: $product-gallery-item-border-radius;
}
.product-gallery__carousel-item--active {
    box-shadow: $product-gallery-item-active-shadow;
}
